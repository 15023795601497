import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Roadmap from "../components/Roadmap";
import GallerySection from "../components/GallerySection";
import TeamSection from "../components/TeamSection";
import FaqSection from "../components/FaqSection";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import CountDown from "../components/CountDown";
import ClubFeatures from "../components/ClubFeatures";

export default function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const cards = [1, 2, 3, 4];
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
    <video className="bg-media__video" playsinline autoplay loop muted>
        <source src="/images/cats.mp4" type="video/mp4"/>
    </video>`,
        }}
      />

      {/* <video className="bg-media__video" loop autoplay="true" muted controls={false} playsinline>
        <source type="video/mp4" src="/images/cats.mp4"></source>
      </video> */}
      {scrollPosition > 200 ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
          }}
          className="scrollArrow"
        >
          <i className="fas fa-arrow-up"></i>
        </a>
      ) : (
        ""
      )}

      <Header />
      <HeroSection />
      <CountDown />
      <GallerySection />

      <ClubFeatures />
      <Roadmap />
      <div id="home" style={{ padding: "3em 0 6em 0" }}>
        <div className="container">
          <div className="row">
            <h3 className="heroHeading">Siamese cats special edition one of a kind ! </h3>
            <h4 className="title">These Special pre-minted cats are only available from the charity dollar shop!</h4>

            <div className="row mx-0">
              <div className="col-6 col-lg-3 mx-auto">
                <img src="/images/siasem.gif" className="img-fluid" alt="" />
              </div>
            </div>

            <a href="https://4nftrescueheroclub.myshopify.com/" target="blank" style={{ width: "auto", margin: "20px auto" }}>
              Buy in Dollar
            </a>

            <h5>
              This is a special one time pre-minted run of 1,500 special Shelter Pet Siamese cats. These are a one time
              special collectable version numbered 1-1500 on collectable NFT cards. 100% of all profits generated from
              the sales of these cards go to save Shelter Pet Animals.
            </h5>
          </div>
        </div>
      </div>

      <TeamSection />
      <FaqSection />
      <Subscribe />
      <Footer />
    </>
  );
}
