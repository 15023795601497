import React from "react";
import FlipCountdown from "@rumess/react-flip-countdown";
import { useHistory } from "react-router-dom";

export default function CountDown() {
  const history = useHistory();
  return (
    <div className="saleEnd" id="Pre-sale">

      <div className="container">
      <h3 className="heroHeading">EVERY 16 SECONDS A SHELTER PET is Euthanized in the USA  </h3>
        <h5 className="title">
        Find Out How a New NFT Artwork Series Is Helping to Save Shelter Pets  <br/> 
& Find Out How You Can Be Part of This Great Humanitarian Effort{" "}
<a className="info" href="#work" >Get More Info</a>
        </h5>
      
        <button onClick={() => history.push("/mint")}>Buy in Polygon</button>
            <a className="button" href="https://4nftrescueheroclub.myshopify.com/" target="blank">Buy in Dollar</a>
        
        
        

        <FlipCountdown
          hideYear
          hideMonth
          dayTitle="Days"
          hourTitle="Hours"
          minuteTitle="Minutes"
          secondTitle="Seconds"
          endAt={"2022-03-01 00:00:00"} // Date/Time
        />
        <div className="container"><a className="buttons" href="https://docs.google.com/forms/d/e/1FAIpQLSfCdDD2gRXVqNbfX7xHBJDcnpZYbVwI6cvzhOMsFJj0dKEciw/viewform?usp=sf_link" target="blank">Register for Pre-sale</a>
        </div>
      </div>
    </div>
  );
}
