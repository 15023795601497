import React from "react";

export default function Subscribe() {
  return (
    <section id="subscribe">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="sub-main">
              <div className="row text-center">
                <div className="col-lg-8">
                  <h3>Join Our Discord Server</h3>
                </div>
                <div className="col-lg-4 subscripe-btn">
                  <a href="https://discord.gg/QpgyDJ5gXW" target="blank">Join us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
