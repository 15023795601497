import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

function App() {
  return (
    <BrowserRouter>
    <div className="App" style={{ backgroundImage: "url(/images/background_1080.png)" }}>
      <Routes />
      </div>
    </BrowserRouter>
  );
}

export default App;