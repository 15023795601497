import React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function FaqSection() {
  return (
    <section id="faqSection">
      <div className="container">
        <h3 className="heroHeading">FAQ</h3>
        <div className="px-2 px-lg-5 ">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>01.When do you give the money to charity?</Accordion.Header>
              <Accordion.Body>Donations will be made within 30 days after each drop.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>02.How do you pick the charity?</Accordion.Header>
              <Accordion.Body>
              The current recipient has been chosen by the founding team. We have chosen the National 
              Greyhound Foundation ( an over 25 year old greyhound adoption and rescue 501 c(3) charity) 
              and their new 2nd Chance at Life Shelter Pet Rescue program. However, after the first 
              collection drop (part 1 & 2) we will open up voting for the community for more local pet 
              rescue shelters to participate.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>03.Will you provide proof of the donations?</Accordion.Header>
              <Accordion.Body>
                Of course. We will always post the donation receipts and show the impact through content whenever
                possible. We will try to live stream donations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>04.We give 100% of all profits to charity ?</Accordion.Header>
              <Accordion.Body>
              Within 30 days of collection we give a check directly to the charity and we will post the charity celebration and check ceremony with pictures & videos.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>05.Are you able to donate to 4charity NFTs?</Accordion.Header>
              <Accordion.Body>
              NO if you want to donate directly to the charity you must go to their website at www.4greyhounds.org.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                06.What happens after I purchase a Priscilla collection cat or Happy her greyhound friend?
              </Accordion.Header>
              <Accordion.Body>
                Your Cat or Greyhound friend will be stored in the wallet you used to purchase them. Your Cat or
                Greyhound friend (s) will be viewable in the MetaMask wallet app and on a Polygon marketplace once we
                have integrated the contract!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>07.What can I do with my Priscilla collection? </Accordion.Header>
              <Accordion.Body>
                It’s yours. You own it outright and are free to use it however you like under a non-exclusive license.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>08.Who is the team behind The Priscilla collection? </Accordion.Header>
              <Accordion.Body>
              Including Ferd , we are a team of 5 other members, Antonio our lead artist and 
              Sassy Cat, our idea girl and our talented programmer and design team of Kritexco.  
              Our skills include design, web development, business strategy, community outreach, 
              and leadership.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>09.Why should you get a Priscilla cat collection? </Accordion.Header>
              <Accordion.Body>
                Well not just because it’s an NFT but because you will be engaging in activism to save our goal of 7,777
                real shelter pets as we will be donating a significant portion of the proceeds from our primary and even
                secondary sales to the National Greyhound Foundation’s “Shelter Pets Rescue Program” to save as many
                real shelter pets as possible.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
}
