import React from "react";

export default function ClubFeatures() {
  return (
    <section id="ClubFeatures">
      <div className="container">
        <h3 className="heroHeading">CLUB FEATURES</h3>
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-md-6">
                <div className="featureContent top">
                  <a href="#">
                    <i class="fa fa-book over-i" aria-hidden="true"></i>
                  </a>
                  <h4>HAPPY’s RESCUE HERO CLUB</h4>
                  <p>
                  Everyone that buys a RESCUE HERO NFT from the Priscilla collection in the 
                  pre-sale will become a member of HAPPY’s RESCUE HERO CLUB and gain special
                  access to the clubhouse! The Clubhouse is a great place to meet new friends 
                  and like-minded people that love to save animals whether it be from shelter pets to wild 
                  and/or endangered species. As a Rescue Hero club member You will be representing 4CharityNFTs 
                  and can invite your local shelters to apply for receiving donation funds from the National Greyhound
                  Foundation 501 c (3) non-profit corporation. More information can be found at www.4greyhounds.org. 
                  You can also sign up to participate in local shelter pet adoption days (when available in your area). <br/>
                  We would LOVE to have you in our club so pop on over to our discord  “HAPPY’s RESCUE HERO CLUB” to talk all things
                  art, NFTs, SAVING SHELTER PETS, charity, and share in our passion for animals and creativity!


                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="featureContent right text-left h-100 ">
                  <a href="#">
                    <i class="fas fa-video over-i" aria-hidden="true"></i>
                  </a>
                  <h4>Unique Priscilla & Happy NFT’s</h4>
                  <p>
                  After the pre-sale has ended the unique Golden Background cat & dog NFT’s
                  will be distributed to our biggest NFT holders these special rare gold 
                  background NFT’s are specified in our contract, with one of a kind traits. There are only 77 of these special rare Priscilla & Happy NFT’s ever made. They will be published after the pre-sale has ended and given as incentive to our biggest holders.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="featureContent left">
                  <a href="#">
                    <i class="fa fa-star over-i" aria-hidden="true"></i>
                  </a>
                  <h3>Cat & Greyhound friend </h3>
                  <h4>77+ Unique Traits <br/>What do 77 + unique traits mean.</h4>
                  
                  <p>
                  Each cat and greyhound friend is uniquely generated by our algorithm. 
                  Within this algorithm, over 77 + unique traits are used and then hand-drawn 
                  by our talented artist Antonio.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="featureContent bottom text-left">
                  <a href="#">
                    <i class="fa fa-lock over-i" aria-hidden="true"></i>
                  </a>
                  <h4>Golden Backgrounds</h4>
                  <p>
                  During the presale, we would like to provide the opportunity for the 
                  "animal saving action takers” among us to join HAPPY’s RESCUE HERO CLUB 
                  in full force to Save as many real Shelter Pets as possible! 
                  Everyone who mints and purchases the largest package of Priscilla’s Collection 
                  NFTs, receives an extremely rare Golden Background Priscilla & Happy NFT for free.
                   However, note that there are only 77 available, and no more will EVER be available!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
