import React from "react";

export default function Roadmap() {
  return (
    <section id="roadmap">
      <div className="container">
        <h3 className="heroHeading ">ROADMAP</h3>
        <div className="mainTimeline">
          {/* <!-- start experience section--> */}
          <div className="timeline">
            <div className="icon"></div>
            <div className="dateContent">
              <div className="dateOuter">
                <span className="date">1 st
                  <span className="month">December</span>
                  <span className="year">2021</span>
                </span>
              </div>
            </div>
            <div className="timelineContent">
              <h5 className="title">NFT Pre-sale</h5>
              <p className="description">
              During the Pre-sale you will get the chance to mint your Priscilla RESCUE HERO
               NFT for a fraction of its potential future price! Also, and most importantly, the 
               money goes to save a living shelter pet (you are giving the GIFT OF LIFE to a living 
               shelter pet) the Pre-sale will consist of exactly 7,700 NFT’s + 77 super rare Golden 
               NFT’s with Priscilla and her greyhound friend Happy! You can only get these for FREE by
               buying 10 or more pre-sale NFT’s.
              </p>
            </div>
          </div>

          {/* <!-- end experience section--> */}

          {/* <!-- start experience section--> */}

          <div className="timeline">
            <div className="icon"></div>
            <div className="dateContent">
              <div className="dateOuter">
                <span className="date">
                  <span className="month">Q4</span>
                  <span className="year">2021</span>
                </span>
              </div>
            </div>
            <div className="timelineContent">
              <h5 className="title">Golden Cats & Greyhound Rarities Incentives</h5>
              <p className="description">
              The Golden Cats & Greyhound rarities incentive will be given to everyone who purchased 10 or more NFT's during pre-sales.
                There will only be 77 ever made. So that makes these 77 Golden Cats & Greyhound rarities Super Rare ! 
              </p>
            </div>
          </div>

          {/* <!-- end experience section--> */}

          {/* <!-- start experience section--> */}
          <div className="timeline">
            <div className="icon"></div>
            <div className="dateContent">
              <div className="dateOuter">
                <span className="date">
                  <span className="month">Q4</span>
                  <span className="year">2021</span>
                </span>
              </div>
            </div>
            <div className="timelineContent" id="work">
              <h5 className="title">Merchandise</h5>
              <p className="description">
              Our artists are busy creating a full range of HAPPY’s RESCUE HERO CLUB merchandise
               for our club members to wear. The merchandise will be a way for you to showcase your 
               membership in our exclusive club and dedication to saving shelter pets.Members will receive extensive discounts based on their NFT portfolio.
              </p>
            </div>
          </div>
          {/* <!-- end experience section--> */}
          {/* <!-- start experience section--> */}

          <div className="timeline" >
            <div className="icon"></div>
            <div className="dateContent">
              <div className="dateOuter">
                <span className="date">
                  <span className="month">Q1</span>
                  <span className="year">2022</span>
                </span>
              </div>
            </div>
            <div className="timelineContent" >
              <h5 className="title">Working with Pet Shelters on Adoption</h5>
              <p className="description">
              We are already hard at work with the National Greyhound Foundation gathering 
              Shelter Partnerships and Racing Greyhound Kennel partners.  These partnerships
               will allow you, as a Club member, to feature your name on a list that represents
               Happy’s Rescue Hero Club and help the most AT RISK and HARD TO ADOPT animals find 
               their “forever homes”. When we work together we have a chance at saving many of the 
               1.5 million plus shelter pets that are euthanized each year across the USA.Thank you for
               speaking on behalf of all the dogs and cats in shelters, your dedication, and your 
               continued support.   


              </p>
            </div>
          </div>
          {/* <!-- end experience section--> */}
        </div>
      </div>
    </section>
  );
}
