import React from "react";
import { useHistory } from "react-router-dom";

export default function HeroSection() {
  const history = useHistory();
  return (
    <section id="heroSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
           {/* <!-- <h1>EVERY 16 SECONDS A SHELTER PET is Euthanized in the USA</h1>
            <p>
              Priscilla the Feral cat and her Greyhound racing friend HAPPY, are saving AT RISK pets at Local Animal
              Shelters and Greyhound Kennel facilities in the USA.
            </p>
            <button onClick={() => history.push("/mint")}>Buy in Polygon</button>
            <a href="https://4nftrescueheroclub.myshopify.com/" target="blank">Buy in Dollar</a>--> */}
          </div>
         
          
                
          <div className="col-lg-6">
            <div>{/* <!-- <img src="./img/bird1.png" alt=""> --> */}</div>
          </div>
        </div>
        <div className="design-layer"></div>
      </div>
    </section>
  );
}
