import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";

export default function Header() {
  const dispatch = useDispatch();
  return (
    <header id="heroHeader">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              4CharityNfts<span>.</span>
            </a>
            <button
              className="navbar-toggler"
              id="togglerIcon"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link active" href="#Home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#Pre-sale">
                    Pre-sale
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#ClubFeatures">
                    How 4charitynft works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <button
                    className="connectBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                    }}
                  >
                    Connect Wallet
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
