import React from "react";

export default function GallerySection() {
  return (
    <section id="gallerySection">
      <div className="container">
        <h3 className="heroHeading">GALLERY</h3>
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <img src="/images/gallery1.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 ">
                <img
                  src="/images/gallery2.jpeg"
                  className="img-fluid"
                  alt=""
                  style={{ height: "90%", width: "100%" }}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <img src="/images/gallery3.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <img src="/images/gallery4.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <img src="/images/gallery5.png" className="img-fluid" alt="" />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <img src="/images/gallery6.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
