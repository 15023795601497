import React from "react";

export default function Footer() {
  return (
    <section id="lastFooter">
      <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{ marginTop: "25px" }}>
            <div className="fop-btm text-center">
              <h2>
                Copyright © 2021. All rights reserved by <a href="#">4charitynfts</a>
              </h2>
              <div className="footerSocial">
                <a href="https://m.facebook.com/4-Charity-NFTs-102646355618426/?tsid=0.24663021403693208&source=result" target="blank"> 
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://mobile.twitter.com/Happys_club" target="blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://t.me/rescuehero" target="blank">
                  <i className="fab fa-telegram"></i>
                </a>
                <a href="https://www.instagram.com/4charitynfts/" target="blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="http://happysrescuehero.club/mail-ing-list.html" target="blank">
                <i class="fab fa-google"></i>
                </a>

                <a href="https://nftaggregator.io/happysrescueheroclub/" target="_blank">
                    <img src="/images/nft.png" className="img-fluid" alt="NFT Aggregator" style={{ marginTop:"-15px"}} />
                  </a>
                
              </div>
              
            </div>
            
          </div>
          
        </div>
      </div>
    </section>
  );
}
