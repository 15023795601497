import React from "react";

export default function TeamSection() {
  return (
    <section id="teamSection">
      <div className="container">
        <h3 className="heroHeading ">TEAM</h3>
        <div className="row">
        <div className="col-lg-3">
            <div className="cards">
              <img src="/images/gold_2.jpg" alt="" />
              <h5>Ferdinand</h5>
              <p>Founder</p>
            </div>
          </div>
          <div className="col-lg-3 text-center text-lg-end">
            <div className="cards">
              <img src="/images/gold22.jpg" alt="" />
              <h5>Cole</h5>
              <p>SR-VP project manager</p>
            </div>
          </div>
          
          <div className="col-lg-3 text-sm-center text-lg-start">
            <div className="cards">
              <img src="/images/Gold_3.png" alt="" />
              <h5>Antonio</h5>
              <p>Lead Designer</p>
            </div>
          </div>
          <div className="col-lg-3 text-sm-center text-lg-start">
            <div className="cards">
              <img src="/images/gold_4.jpg" alt="" />
              <h5>Kritexco</h5>
              <p>Developer & Blockchain Expert</p>
            </div>
          </div>

          
          
        </div>
        <div className="design-layer"></div>
      </div>
    </section>
  );
}
